import React from "react";

export const LogoIcon = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="34px"
      height="34px"
      viewBox="0 0 600 600"
      enableBackground="new 0 0 600 600"
      xmlSpace="preserve"
    >
      <path
        id="color_x5F_2"
        fill="#6c6e70"
        d="M302.129,538.646c3.714,11.14-4.578,22.645-16.321,22.645h-107.1    c-11.743,0-20.035-11.504-16.321-22.645l11.806-35.42h116.129L302.129,538.646z M507.527,154.839H479.57    c-8.314,0-15.054,6.74-15.054,15.054v12.44c-2.437,22.905-10.592,45.058-43.409,49.925h-77.021c-8.314,0-15.054,6.74-15.054,15.054    v86.022c0,8.314,6.74,15.054,15.054,15.054h86.022c8.314,0,15.054-6.74,15.054-15.054v-77.021    c4.867-32.816,27.019-40.972,49.924-43.409h12.442c8.314,0,15.054-6.74,15.054-15.054v-27.957    C522.581,161.578,515.841,154.839,507.527,154.839z M584.946,77.419h-27.957c-8.314,0-15.054,6.74-15.054,15.054v27.957    c0,8.314,6.74,15.054,15.054,15.054h27.957c8.314,0,15.054-6.74,15.054-15.054V92.473C600,84.159,593.26,77.419,584.946,77.419z"
      />
      <path
        id="color_x5F_1_3_"
        fill="#5ca9fb"
        d="M430.108,367.742h-86.022c-18.973,0-34.409-15.436-34.409-34.409v-86.022    c0-18.973,15.436-34.409,34.409-34.409h19.355c-18.973,0-34.409-15.436-34.409-34.409v-4.301H38.71    c-21.379,0-38.71,17.331-38.71,38.71v232.258c0,21.379,17.331,38.71,38.71,38.71h387.097c21.379,0,38.71-17.331,38.71-38.71H440.86    c-8.314,0-15.054-6.74-15.054-15.054v-27.957c0-8.314,6.74-15.054,15.054-15.054h23.656v-53.763    C464.516,352.306,449.081,367.742,430.108,367.742z M270.968,313.978c0,8.314-6.74,15.054-15.054,15.054h-27.957    c-8.314,0-15.054-6.74-15.054-15.054v-27.957c0-8.314,6.74-15.054,15.054-15.054h27.957c8.314,0,15.054,6.74,15.054,15.054V313.978z     M488.172,38.71c8.314,0,15.054,6.74,15.054,15.054V81.72c0,8.314-6.74,15.054-15.054,15.054h-12.931    c-21.942,1.53-37.437,9.396-39.758,36.154v45.566c0,8.314-6.74,15.054-15.054,15.054h-56.989c-8.314,0-15.054-6.74-15.054-15.054    v-56.989c0-8.314,6.74-15.054,15.054-15.054h45.567c26.756-2.321,34.623-17.815,36.153-39.756V53.763    c0-8.314,6.74-15.054,15.054-15.054H488.172z M541.935,266.667v27.957c0,8.314-6.74,15.054-15.054,15.054h-27.957    c-8.314,0-15.054-6.74-15.054-15.054v-27.957c0-8.314,6.74-15.054,15.054-15.054h27.957    C535.196,251.613,541.935,258.353,541.935,266.667z"
      />
    </svg>
  );
};
